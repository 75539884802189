<template>
  <div class="note-wrapper" @scroll="handleScroll">
    <div class="title">
      <h3>个人日志</h3>
      <h5>开启您的择友之旅~</h5> 
    </div>
    <div class="note-list">
      <div class="row" v-for="(item,index) in list" :key="index">
        <div class="date">日期：{{item.updatetime}}
          <el-popconfirm v-if="edit"
            title="确定删除这条日记吗？"
            @confirm="deletenote(item.id)"
          >
            <span slot="reference">删除</span>
          </el-popconfirm>
        </div>
        <div class="brief">
          <p>
           {{ item.content }}
          </p>
        </div>
        <div class="pic-list" v-if="item.images">
          <!-- <div class="block" v-for="(img,imgindex) in item.images" :key="imgindex">
            <a data-fancybox="gallery1" :href="img" :data-thumb="img">
            <video src="@/assets/video.mp4" alt="" poster="@/assets/note-pic.jpg"></video>
            <div class="play-btn"><i class="el-icon-caret-right"></i></div>
          </a> -->
          <!-- </div> -->
          <div class="block" v-for="(img,imgindex) in item.images" :key="imgindex">
            <a :data-fancybox="'gallery'+index" :href="img" :data-thumb="img">
              <img v-if="img.indexOf('.mp4')>0" :src="img+'?x-oss-process=video/snapshot,t_10000,m_fast'" alt="">
              <img v-else  :src="img" alt="">
            </a>
          </div>
          
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {getDiaryList,deleteDiray} from '@/api/api'
export default {
  name: "notePage",
  data() {
    return {
      list:[],
      edit:false,
      page:1,
      last_page:1
    };
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll)
  },
  watch:{
    '$route.params.id':{
      handler(newVal,oldVal){
        this.init()
      },
      immediate: true
    }
  },
  created(){
    // this.getDiaryList()
    
  },
  methods: {
    init(){
      this.user_id = this.$route.params.id
      this.self_userinfo = JSON.parse(localStorage.getItem('userinfo'))
      if(this.user_id==this.self_userinfo.id){
        this.edit = true
      }
      this.getDiaryList()
    },
    deletenote(id){
      var param = {
        id
      }
      deleteDiray(param).then(res=>{
        if(res.data.code==1){
          this.$message.success(res.data.msg)
          this.list=[]
          this.getDiaryList()
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    handleScroll(e) {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const clientHeight = document.documentElement.clientHeight;
    
      // 检查是否滚动到了页面底部
      if (scrollHeight - scrollTop - clientHeight < 200) {
        // 200 是一个滚动到底部前的偏移量
          
          if(this.last_page>this.page){
            this.page++
            this.getDiaryList()
          }
          
        // 执行的代码......
      }
    },
    
    getDiaryList(){
      var that = this
      var param = {
        page:this.page,
        user_id:this.user_id
      }
      getDiaryList(param).then(res => {
            if(res.data.code==1){
              that.list = that.list.concat(res.data.data.data)
              this.last_page = res.data.data.last_page
            }
            console.log(res.data.data.data)
          }).catch(err => console.log(err))
          console.log(this.list)
    },
    handleConfirm () {
      console.log('删除');
    },
  },
};
</script>

<style scoped lang="less">
.note-wrapper {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 5%;
  .title {
    margin-bottom: 3.704%;
    h3 {
      font-size: 28px;
      color: #fff;
      margin-bottom: 0.357143em;
    }
    h5 {
      font-size: 18px;
      color: #ebebeb;
      font-weight: normal;
    }
  }
  .note-list {
    .row {
      margin-bottom: 4.4444%;
      &:last-child {
        margin-bottom: 0;
      }
      .date {
        font-size: 20px;
        color: #fff;
        margin-bottom: 0.7em;
        span {
          cursor: pointer;
          font-size: 18px;
          color: #ff8ad7;
          margin-left: 0.8889em;
        }
      }
      .brief {
        font-size: 14px;
        color: #f0f0f0;
        line-height: 1.8;
        margin-bottom: 1em;
      }
    }
  }
  .pic-list {
    display: flex;
    flex-wrap: wrap;
    gap: 36px;
    .block {
      position: relative;
      width: 150px;
      height: 114px;
      display: flex;
      justify-content: center;
      align-items: center;
      img, video {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
      }
      .play-btn {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        width: 23px;
        height: 23px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 50%;
        i {
          color: #fff;
        }
      }
    }
  }
}
</style>
