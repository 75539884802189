<template>
  <div>
    <div class="home-title">
      <div style="width: 1200px; margin: 0 auto;"> {{ $route.meta.title }}
        <span @click="logout" style="font-size: 14px; float: right; line-height: 52px; font-weight: normal; color: #ff8ad7; cursor: pointer;">退出登录</span></div>
     
    </div>
    <header>
      <div class="container">
        <router-link :to="$route.meta.isEditPage?'/changeAvatar':'/userInfo/'+userInfo.id" class="avatar-wrapper">
          <div class="avatar" :class="$route.meta.isEditPage?'editAvatar':''">
            <div class="pic">
              <img :src="userInfo.avatar" alt="">
            </div>
            <div class="ribbon">Lv{{userInfo.level }}</div>
          </div>
          <div class="info">
            <h4>{{userInfo.nickname}}  </h4>
            <h5>{{userInfo.username}}</h5>
          </div>
        </router-link>
        <el-menu :default-active="$route.meta.index" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1">
            <router-link to="/">
              <img src="@/assets/home-icon.png" alt="">
              <span>主页大厅</span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="2">
            <router-link to="/writeNote">
              <img src="@/assets/note-icon.png" alt="">
              <span>写日记</span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="3">
            <router-link to="/search">
              <img src="@/assets/search-icon.png" alt="">
              <span>搜索</span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="4">
            <router-link to="/msg">
              <el-badge :value="number" class="item">
                <img src="@/assets/msg-icon.png" alt="">
              </el-badge>
              <span>消息</span>
            </router-link>
          </el-menu-item>
        </el-menu>
      </div>
    </header>
  </div>
</template>

<script>
import {logout,getNoticeNum} from '@/api/api'
export default {
  name: "HeaderComponent",
  data() {
    return {
      userAvatarSrc: require("@/assets/user-avatar.png"),
      userInfo:JSON.parse(localStorage.getItem('userinfo')),
      number:0
    };
  },
  created(){
    this.getMessage()
  },
  watch: {
    $route(to, from) {
      this.getMessage()
    },
  },
  methods: {
    getMessage(){
      getNoticeNum().then(res=>{
        this.number = res.data.data.number
        console.log(res.data.data.number)
      })
     
    },
    logout(){
      
      logout().then(res => {
        if(res.data.code==1){
          localStorage.setItem('ecy_token','')
          localStorage.setItem('userinfo','')
              this.$router.push({
                name: "logoutSuccess",
              });
        }
        
      }).catch(err => this.$message.error(res.data.msg))
      console.log(1111)
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style scoped lang="less">
header {
  margin-bottom: 46px;
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  ::v-deep {
    .el-menu {
      border: 0;
      background-color: transparent;
      .el-menu-item {
        line-height: 2;
        height: auto;
        margin: 0 0 0 69px;
        padding: 0;
        &:first-child {
          margin-left: 0;
        }
      }
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        img {
          display: inline-block;
          width: 64px;
          height: 54px;
          object-fit: scale-down;
          filter: contrast(0) brightness(200%);
        }
        span {
          font-size: 20px;
          color: #fff;
          font-weight: 700;
          margin-top: 0.5em;
        }
      }
    }
    .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
      background-color: transparent;
    }
    .el-menu--horizontal>.el-menu-item.is-active {
      border-color: transparent;
      img {
        filter: contrast(1) brightness(100%);
      }
      span {
        color: #ff8ad7;
      }
    }
    .el-badge__content {
      border: 0;
      background-color: #e11e1e;
      font-size: 20px;
      line-height: 28px;
      height: 28px;
      min-width: 28px;
      box-sizing: border-box;
      padding: 0 6px;
      border-radius: 14px;
    }
  }
}
</style>