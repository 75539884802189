<template>
  <div class="edit-info">
    <div class="title">
      <h3>个人信息</h3>
      <h5>开启您的择友之旅~</h5>
    </div>

    <el-form :model="form" :inline="true" ref="form" label-width="95px">
      <el-form-item label="姓名" style="margin-right: 50px;">
        <el-input v-model="form.nickname" placeholder="请输入您的姓名"></el-input>
      </el-form-item>
      <el-form-item label="出生日期" style="margin-right: 50px;">
        <el-date-picker type="date" placeholder="选择出生日期" value-format="yyyy-MM-dd" v-model="form.birthday" style="width: 100%;"></el-date-picker>
      </el-form-item>
      <el-form-item label="身高(cm)" style="margin-right: 50px;">
        <el-input v-model="form.height" placeholder="请输入您的身高"></el-input>
      </el-form-item>
      <el-form-item label="体重(kg)" style="margin-right: 50px;">
        <el-input v-model="form.weight" placeholder="请输入您的体重"></el-input>
      </el-form-item>
      <el-form-item v-if="form.gender == '1'" label="年收入" style="margin-right: 50px;">
        <el-input v-model="form.income" placeholder="请输入您的年收入"></el-input>
      </el-form-item>
      <el-form-item v-else-if="form.gender == '0'" label="三围" style="margin-right: 50px;">
        <el-input v-model="form.measurements" placeholder="请输入您的三围"></el-input>
      </el-form-item>
    
      <el-form-item label="学历" label-width="100px" prop="education" style="margin-right: 50px;">
          <el-select v-model="form.education" placeholder="请选择您的学历">
            <el-option label="小学" value="小学"></el-option>
            <el-option label="初中" value="初中"></el-option>
            <el-option label="中专" value="中专"></el-option>
            <el-option label="高中" value="高中"></el-option>
            <el-option label="大专" value="大专"></el-option>
            <el-option label="本科" value="本科"></el-option>
            <el-option label="硕士" value="硕士"></el-option>
            <el-option label="博士" value="博士"></el-option>
          </el-select>
        </el-form-item>


      <el-form-item label="职业" style="margin-right: 50px;">
        <el-input v-model="form.occupation" placeholder="请输入您的职业"></el-input>
      </el-form-item>
      <el-form-item label="现居住地" label-width="100px" prop="province" >
          <el-select v-model="form.province" placeholder="请选择市/区" @change="changeprovice">
            <el-option v-for="(item,index) in province_list" :key="index" :label="item.name" :value="item.id"></el-option>
      
          </el-select>
          <el-select v-model="form.city" placeholder="请选择区/县" style="margin-left: 50px;">
            <el-option v-for="(item,index) in city_list" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
     
      <el-form-item label="性格特点" style="margin-right: 50px; width: 100%;">
        <el-input v-model="form.personality" placeholder="请输入您的性格特点"></el-input>
      </el-form-item>
      <el-form-item label="兴趣爱好" style="margin-right: 50px; width: 100%;">
        <el-input v-model="form.interest" placeholder="请输入您的兴趣爱好"></el-input>
      </el-form-item>
      <el-form-item label="恋爱愿望" style="margin-right: 50px;">
        <el-checkbox-group v-if="form.gender == '1'" v-model="form.wish">
          <el-checkbox label="只追求恋爱女友不追求结婚" ></el-checkbox>
          <el-checkbox label="追求恋爱女友又追求结婚" ></el-checkbox>
          <el-checkbox label="追求恋爱女友步入婚姻殿堂" ></el-checkbox>
        </el-checkbox-group>
        <el-checkbox-group v-else v-model="form.wish">
          <el-checkbox label="只追求恋爱男友不追求结婚" ></el-checkbox>
          <el-checkbox label="追求恋爱男友又追求结婚" ></el-checkbox>
          <el-checkbox label="追求恋爱男友步入婚姻殿堂" ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>

    <div class="user-pic content-list">
      <h5 class="list-title">
        个人照片 <span>至少十八张（照片小于5M，照片可以添加，修改或者删除）</span>
      </h5>
      <div class="wrapper">
        <div class="block" v-for="(item,index) in fileList" :key="index">
          <a data-fancybox="images"  :href="item" :data-thumb="item">
            <img :src="item" />
          </a>
          <i class="el-icon-close" @click="removePhotos(index)"></i>
        </div>

        <el-upload
        list-type="picture-card"
        action="https://erciyuanla.com/api/common/upload"
        :on-success="handlePicSuccess"
        :before-upload="beforeUpload"
        :on-remove="handleRemove"
        multiple
        class="upload-wrapper">
          <i slot="default" class="el-icon-plus"></i>
          <em>点击上传</em>
         
        </el-upload>
      </div>
    </div>
    <div class="user-video content-list">
      <h5 class="list-title">
        个人视频 <span>至少一个视频(视频小于5分钟，400M，视频可以添加，修改或者删除)</span>
      </h5>
      <div class="wrapper">
        <div class="block" v-for="(item,index) in fileVideoList" :key="index">
          <a data-fancybox="video"  :href="item">
            <video :src="item" alt="" ></video>
            <div class="play-btn"><i class="el-icon-caret-right"></i></div>
          </a>
          <i class="el-icon-close" @click="removeVideo(index)"></i>
        </div>
        <el-upload
        list-type="picture-card"
        action="https://erciyuanla.com/api/common/upload"
        :on-success="handleVideoSuccess"
        :before-upload="beforeUpload"
        :on-remove="handleRemoveVideo"
        multiple
        class="upload-wrapper">
          <i slot="default" class="el-icon-plus"></i>
          <em>点击上传</em>
          
        </el-upload>
      </div>
    </div>


    <el-button type="primary" @click="submitForm('form')">提交</el-button>
  </div>
</template>

<script>
import {profile,getAreaListByPid,userinfo} from '@/api/api'
export default {
  name: "editUserInfo",
  data() {
    return {
      disabled: false,
      gender: 'm',
      city_list:[],
      province_list:[],
      fileList:[],
      fileVideoList:[],
      form: {
        nickname: "某某某",
        birthday: "2000-12-28",
        height: "",
        weight: "",
        income: "",
        measurements: "",
        education: "",
        occupation: "",
        province: "",
        city:"",
        personality: "",
        interest: "",
        wish: [],
      },
      userInfo:{},
      imagearr:[],
      videoarr:[]
    };
  },
  mounted(){
    // nickname: "",
    //     gender:0,
    //     birthday: "",
    //     height: "",
    //     weight: "",
    //     education: "",
    //     occupation: "",
    //     salary: "",
    //     interest: "",
    //     province: "",
    //     city: "",
    //     avatar: "",
    //     personal_photos: "",
    //     identity_card_font: "",
    //     identity_card_back: "",
    this.getarealist(0)
    this.getUserinfo()
  },
  methods: {
    getarealist(pid){
      var param = {
        pid
      }
      getAreaListByPid(param).then(res => {
            if(res.data.code==1){
              console.log(res.data.data)
              if(pid==0){
                this.province_list = res.data.data
              }else{
                this.city_list = res.data.data
              }
              
            }
            
          }).catch(err => this.$message.error(res.data.msg))
    },
    changeprovice(e){
      this.city_list = []
      this.getarealist(e)
      console.log(e)
    },
    getUserinfo(){
      userinfo().then(res=>{
        console.log(res.data)
        this.form = res.data.data
        this.userInfo = res.data.data
        this.changeprovice(this.form.province)
        this.fileList = res.data.data.personal_photos
        this.fileVideoList = res.data.data.videos
      }).catch(err=>{
        console.log(err)
      })
    },
    // 删除日记
    handleConfirm () {
      console.log('删除');
    },
    handlePicSuccess (res, file) {
      if(res.code==1){
        this.imagearr.push(res.data.fullurl)
      }else{
        this.$message.error(res.msg)
      }
    },
    handleVideoSuccess (res, file) {
      if(res.code==1){
        this.videoarr.push(res.data.fullurl)
      }else{
        this.$message.error(res.msg)
      }
    },
    beforeUpload (file) {

    },
    // 删除个人照片
    handleRemove(file,fileList) {
      var removeitem = file.response.data.fullurl
      this.imagearr = this.imagearr.filter(item=> item!=removeitem);
    },
    handleRemoveVideo(file,fileList){
      var removeitem = file.response.data.fullurl
      this.videoarr = this.videoarr.filter(item=> item!=removeitem);
    },
    // 删除历史照片
    removePhotos (index) {
      this.fileList.splice(index, 1);
    },
    // 删除历史视频
    removeVideo (index) {
      this.fileVideoList.splice(index, 1);
    },
    // 提交表单
    submitForm () {
       
        this.image_arr = this.imagearr.concat(this.fileList)
        this.form.personal_photos = this.image_arr.join(',')

        this.video_arr = this.videoarr.concat(this.fileVideoList)
        this.form.videos = this.video_arr.join(',')

        profile(this.form).then(res=>{
        console.log(res.data)
        if(res.data.code==1){
          this.$message.success('修改成功')
        }
      }).catch(err=>{
        console.log(err)
      })
    },
  },
};
</script>

<style scoped lang="less">
.edit-info {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 5%;
  .title {
    margin-bottom: 3.704%;
    h3 {
      font-size: 28px;
      color: #fff;
      margin-bottom: 0.357143em;
    }
    h5 {
      font-size: 18px;
      color: #ebebeb;
      font-weight: normal;
    }
    a {
      float: right;
      color: #ff8ad7;
    }
  }
  .user-info {
    table {
      width: 100%;
      tr {
        td {
          color: #dcdcdc;
          font-size: 18px;
          padding: 0.5em 0.5em 0.5em 0;
          min-width: 20%;
          span {
            color: #fff;
            font-size: 20px;
          }
        }
      }
    }
  }
  .list-title {
    font-size: 20px;
    color: #fff;
    font-weight: normal;
    margin-bottom: 0.8em;
    overflow: hidden;
    a {
      float: right;
      color: #ff8ad7;
    }
    span {
      font-size: 18px;
      color: #d9d9d9;
      margin-left: 16px;
    }
  }
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    margin-left: 100px;
    .block {
      width: 150px;
      height: 114px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img, video {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
      }
      .play-btn {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        width: 23px;
        height: 23px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 50%;
        i {
          color: #fff;
        }
      }
      .el-icon-close {
        position: absolute;
        z-index: 1;
        background-color: #c3c3c3;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        line-height: 11px;
        text-align: center;
        cursor: pointer;
        right: 0;
        top: 0;
        transform: translate(50%, -50%);
        &::before {
          color: #fff;
        }
      }
      .el-icon-edit-outline {
        &::before {
          color: #fff;
        }
      }
    }
  }
  .content-list {
    margin: 10px 0 33px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .note-list {
    .list {
      margin-left: 100px;
    }
    .row {
      margin-bottom: 4.4444%;
      &:last-child {
        margin-bottom: 0;
      }
      .date {
        font-size: 20px;
        color: #fff;
        margin-bottom: 0.7em;
        span {
          font-size: 18px;
        }
        em {
          cursor: pointer;
          font-size: 18px;
          color: #ff8ad7;
          font-style: normal;
          margin-left: 2em;
        }
      }
      .brief {
        font-size: 16px;
        color: #f0f0f0;
        line-height: 1.8;
        margin-bottom: 1em;
      }
    }
  }
  .pic-list {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    .block {
      position: relative;
      width: 150px;
      height: 114px;
      img, video {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  ::v-deep {
    .upload-wrapper {
      display: flex;
      flex-wrap: wrap;
    }
    .el-upload--picture-card {
      width: 150px;
      height: 114px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      line-height: 2;
      background-color: #c0bebe;
      border-radius: 0;
      em {
        display: block;
        margin: 0 auto;
        font-size: 18px;
        color: #ececec;
        font-style: normal;
        line-height: 2;
      }
    }
    .el-upload--picture-card:hover, .el-upload:focus {
      border-color: #ededed;
      color: #ededed;
    }
    .el-upload--picture-card i {
      color: #ececec;
    }
    .el-upload-list--picture-card .el-upload-list__item {
      // overflow: visible;
      vertical-align: middle;
      width: 150px;
      height: 114px;
      background-color: transparent;
      border: 1px dashed #c0ccda;
      border-radius: 0;
      margin: 0 14px 14px 0;
    }
    .el-upload-list--picture-card .el-upload-list__item-thumbnail {
      position: absolute;
      object-fit: contain;
    }
    .el-icon-close {
      display: block !important;
      right: 0;
      top: 0;
      transform: translate(50%, -50%);
      background-color: #c3c3c3;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      line-height: 9px;
      &::before {
        color: #fff;
        font-size: 12px;
      }
    }
    .el-button {
      width: 480px;
      height: 60px;
      color: #fff;
      font-size: 18px;
      display: block;
      margin: 22px auto;
      background-color: #ff8ad7;
      border: 0;
      &:hover, &:focus {
        background-color: #fda5df;
      }
    }
    .el-form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .el-form-item {
      display: flex;
      margin-bottom: 22px;
    }
    .el-form-item__content {
      flex: 1;
    }
    .el-form-item__label {
      position: relative;
      font-size: 20px;
      color: #fff;
      margin-right: 4px;
      &::before {
        position: absolute;
        right: 0;
        transform: translateX(50%);
        color: #f50202;
      }
    }
    .el-input__inner {
      width: 100%;
      height: 50px;
      min-width: 350px;
      line-height: 50px;
      background-color: transparent;
      border: 1px solid #fff;
      color: #fff;
      font-size: 18px;
      &::placeholder {
        color: #e3e3e3;
      }
      &:focus {
        border-color: rgba(255, 255, 255, 0.5);
      }
    }
    .el-checkbox {
      color: #fff;
      margin-right: 100px;
      &:last-child {
        margin-right: 0;
      }
    }
    .el-checkbox__label {
      font-size: #fff;
      font-size: 18px;
    }
    .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #fff;
    }
    .el-checkbox__inner {
      width: 20px;
      line-height: 20px;
      height: 20px;
      border-radius: 4px;
      background: transparent;
      border-color: #fff !important;
      &::after {
        height: 12px;
        width: 5px;
        left: 6px;
        border-width: 2px;
      }
    }
    .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background: transparent;
    }
  }
}
</style>
