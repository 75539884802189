import http from '../utils/http'
// 
/** * @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/... * @param '/testIp'代表vue-cil中config，index.js中配置的代理 */
let resquest = "https://erciyuanla.com/api/user"

// get请求
export function getListAPI(params){ 
   
    return http.get(`${ 
     resquest}/getList.json`,params)
}
// post请求
export function login(params){ 
   
    return http.post(`${ 
     resquest}/login`,params)
}
// 日记列表
export function getDiaryList(params){ 
   
    return http.post(`${ 
     resquest}/getDiaryList`,params)
}
//发布日记
export function adddiary(params){ 
   
    return http.post(`${ 
     resquest}/adddiary`,params)
}
//删除日记
export function deleteDiray(params){ 
   
    return http.post(`${ 
     resquest}/deleteDiray`,params)
}

//获取城市
export function getAreaListByPid(params){ 
   
    return http.post(`${ 
     resquest}/getAreaListByPid`,params)
}
//注册
export function register(params){ 
   
    return http.post(`${ 
     resquest}/register`,params)
}

//个人主页
export function userinfo(params){ 
   
    return http.post(`${ 
     resquest}/userinfo`,params)
}
//修改资料 
export function profile(params){ 
   
    return http.post(`${ 
     resquest}/profile`,params)
}

//头像列表

export function avater(params){ 
   
    return http.post(`${ 
     resquest}/avater`,params)
}
//修改头像

export function change_avatar(params){ 
   
    return http.post(`${ 
     resquest}/change_avatar`,params)
}

//

export function regsuccdesc(params){ 
   
    return http.post(`${ 
     resquest}/regsuccdesc`,params)
}
//退出登录  
export function logout(params){ 
   
    return http.post(`${ 
     resquest}/logout`,params)
}
//查询用户
export function searchUser(params){ 
   
    return http.post(`${ 
     resquest}/searchUser`,params)
}
//消息列表
export function getNoticeList(params){ 
   
    return http.post(`${ 
     resquest}/getNoticeList`,params)
}
//消息列表数量
export function getNoticeNum(params){ 
   
    return http.post(`${ 
     resquest}/getNoticeNum`,params)
}
//消息详情

export function getNoticeDetail(params){ 
   
    return http.post(`${ 
     resquest}/getNoticeDetail`,params)
}
// put 请求
export function putSomeAPI(params){ 
   
    return http.put(`${ 
     resquest}/putSome.json`,params)
}
// delete 请求
export function deleteListAPI(params){ 
   
    return http.delete(`${ 
     resquest}/deleteList.json`,params)
}