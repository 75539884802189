<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style>
body {
  margin: 0;
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
a {
  text-decoration: none;
}
p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul li {
  padding: 0;
  margin: 0;
}
.blank {
  height: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
  margin: 0 !important;
  padding: 0 !important;
  background: none !important;
}
.el-popup-parent--hidden {
  padding-right: 0 !important;
  overflow: auto !important;
}
.v-modal {
  background: transparent !important;
}
.el-popover p {
  margin-bottom: 1em;
}
.el-popover .el-button--text {
  color: #ff8ad7;
}
.el-popover .el-button--text:hover,
.el-popover .el-button--text:focus {
  color: #fda5df;
}
.el-popover .el-button--primary {
  background-color: #ff8ad7;
  color: #fff;
  border-color: transparent;
}
.el-popover .el-button--primary:hover,
.el-popover .el-button--primary:focus {
  background: #fda5df;
  border-color: transparent;
}
</style>
