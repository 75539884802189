<template>
  <div class="msg-wrapper">
    <div class="avatar-change-wrapper">
      <div class="my-avatar block">
        <img :src="user_info.avatar" alt="">
      </div>
      <div class="block" @click="openDialog(item)" v-for="(item,index) in list" :key="index">
        <img :src="item.image" alt="">
      </div>
    
      <el-dialog :visible.sync="dialogVisible">
        <div class="block">
          <img :src="choose_image" alt="">
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="handleConfirm()">确 定</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {avater,change_avatar} from '@/api/api'
import { 
  Message} from 'element-ui';
export default {
  name: "changeAvatar",
  data() {
    return {
      dialogVisible: false, 
      choose_image:'',
      user_info : JSON.parse(localStorage.getItem('userinfo')),
      list:[]
    };
  },
  created(){
    this.getavaterList()
  },
  methods: {
    openDialog (item) {
      this.choose_image = item.image
      this.dialogVisible = true;
    },
    getavaterList(){
      avater().then(res => {
            if(res.data.code==1){
              this.list = res.data.data
            }
            console.log(res.data.data.data)
          }).catch(err => console.log(err))
    },
    handleConfirm () {
      change_avatar({avatar:this.choose_image}).then(res => {
            if(res.data.code==1){
              
              this.user_info.avatar = this.choose_image
              localStorage.setItem('userinfo',JSON.stringify(this.user_info))
              window.location.reload()
              Message.success('修改成功')
              console.log('修改成功')
            }
            
          }).catch(err => console.log(err))
      
    },
  },
};
</script>

<style scoped lang="less">
.msg-wrapper {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 5% 0 5% 5%;
  .avatar-change-wrapper {
    overflow: hidden;
    .block {
      cursor: pointer;
      float: left;
      margin: 0 40px 40px 0;
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      width: 115px;
      height: 115px;
      img {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .my-avatar {
      width: 268px;
      height: 268px;
    }
    ::v-deep {
      .el-dialog {
        max-width: 94%;
        width: 482px;
        background-color: rgba(255, 255, 255, 0.5);
        .el-dialog__body {
          padding: 10% 8.2988% 20.747%;
          .block {
            width: 210px;
            height: 210px;
            margin: 0 auto;
            float: none;
          }
        }
        .el-dialog__footer {
          padding: 0 8.2988% 15.14523%;
          text-align: center;
          justify-content: space-between;
          .el-button {
            font-size: 18px;
            border-color: transparent;
            width: 170px;
            height: 50px;
            line-height: 50px;
            padding: 0;
            color: #333;
            background-color: #fff;
            &:hover {
              color: #ff8ad7;
            }
          }
          .el-button--primary {
            background-color: #ff8ad7;
            color: #fff !important;
            &:hover {
              background-color: #fda5df;
            }
          }
        }
      }
    }
  }
}
</style>
