<template>
  <div class="write-note-wrapper">
    <el-form
      :model="noteForm"
      :rules="rules"
      ref="noteForm"
      label-width="0"
    >
      <el-form-item prop="title">
        <el-input v-model="noteForm.title" placeholder="添加标题"></el-input>
      </el-form-item>
      <el-form-item prop="content" style="margin-bottom: 30px;">
        <el-input type="textarea" v-model="noteForm.content" placeholder="请输入内容" maxlength="300" show-word-limit resize="none" autosize></el-input type="textarea">
      </el-form-item>
      <el-form-item prop="picture" style="margin-bottom: 47px;">
        <el-upload
        action="https://erciyuanla.com/api/common/upload"
        list-type="picture-card"
        :auto-upload="true"
        :on-success="handlePicSuccess"
        :before-upload="beforeUpload"
         :on-remove="handleRemove"
         :on-preview="handlePictureCardPreview"
        multiple
        class="upload-wrapper">
          <i slot="default" class="el-icon-plus"></i>
          <em>点击上传</em>
          
        </el-upload>
      </el-form-item>
      <el-button
        type="primary"
        @click="submitForm('noteForm')"
      >
        提交
      </el-button>
    </el-form>
    <el-dialog :visible.sync="dialogVisibleimg">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <div class="block">
        <img src="@/assets/avatar.png" alt="">
        <img class="check-icon" src="@/assets/check-icon.png" alt="">
      </div>
      <h4>提交成功</h4>
      <router-link to="/note"><el-button>确 定</el-button></router-link>
    </el-dialog>
  </div>
</template>

<script>
import {adddiary} from '@/api/api'

export default {
  name: "writeNote",
  data() {
    return {
      noteForm: {
        title: "",
        content: "",
        images: "",
      },
      imagearr: [],
      dialogVisibleimg:false,
      dialogVisible: false,
      disabled: false,
      dialogImageUrl:'',
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ],
        images: [
          { required: true, message: '请上传图片', trigger: 'blur' }
        ],
      }
    };
  },
  methods: {
    handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisibleimg = true;
      },
    // 上传照片成功
    handlePicSuccess(res, file) {
      console.log(res.msg)
      if(res.code==1){
        this.imagearr.push(res.data.fullurl)
        this.noteForm.images = this.imagearr.join(',')
      }else{
        this.$message.error(res.msg)
      }
      
    },
    // 删除个人照片
    handleRemove(file,fileList) {
      var removeitem = file.response.data.fullurl
      this.imagearr = this.imagearr.filter(item=> item!=removeitem);
      this.noteForm.images = this.imagearr.join(',')
      console.log(this.imagearr);
    },
    // 图片上传验证
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 6;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG, PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isJPG && isLt2M;
    },
    // 提交表单验证
    submitForm(formName) {
      
      this.$refs[formName].validate((valid) => {
        if (valid) {
          adddiary(this.noteForm).then(res => {
            if(res.data.code==1){
              this.dialogVisible = true;
            }
            console.log(res)
          }).catch(err => this.$message.error(res.data.msg))
          console.log(this.ruleForm.region);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

  },
};
</script>

<style scoped lang="less">
.write-note-wrapper {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 5%;
  border-radius: 24px;
  ::v-deep {
    .el-input__inner {
      background-color: transparent;
      border: 0;
      border-bottom: 1px dashed #f2f2f2;
      font-size: 30px;
      color: #fff;
      padding: 0 0 0.9em;
      box-sizing: content-box;
      &::placeholder {
        color: #f1f1f1;
      }
    }
    .el-textarea__inner {
      background-color: transparent;
      border: 0;
      font-size: 18px;
      font-family: inherit;
      padding: 1em 0 1.5em;
      color: #fff;
      min-height: 154px !important;
      &::placeholder {
        color: #f1f1f1;
      }
    }
    .el-textarea .el-input__count {
      background-color: transparent;
      font-size: 18px;
      line-height: 1;
      color: #fff;
    }
    .el-button {
      width: 100%;
      height: 60px;
      color: #fff;
      font-size: 18px;
      display: block;
      margin: 22px auto;
      background-color: #ff8ad7;
      border: 0;
      &:hover {
        background-color: #fda5df;
      }
    }
    .el-upload--picture-card {
      width: 170px;
      height: 114px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      line-height: 2;
      background-color: #f8f8f8;
      border-radius: 0;
    }
    .el-upload--picture-card:hover, .el-upload:focus {
      border-color: #dedede;
      color: #ededed;
    }
    .el-upload--picture-card i {
      color: #b6b5b5;
    }
    .el-upload-list--picture-card .el-upload-list__item {
      // overflow: visible;
      vertical-align: middle;
      width: 170px;
      height: 114px;
      background-color: transparent;
      border: 1px dashed #dedede;
      border-radius: 0;
    }
    .el-upload-list--picture-card .el-upload-list__item-thumbnail {
      position: absolute;
      object-fit: contain;
    }
    .upload-wrapper {
      em {
        display: block;
        margin: 0 auto;
        font-size: 18px;
        color: #b6b5b5;
        font-style: normal;
        line-height: 2;
      }
      display: flex;
      flex-wrap: wrap;
      .el-icon-close {
        display: block;
        right: 0;
        top: 0;
        transform: translate(50%, -50%);
        background-color: #c3c3c3;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        line-height: 9px;
        &::before {
          color: #fff;
          font-size: 12px;
        }
      }
    }
    .el-dialog__wrapper {
        background-color: transparent;
        backdrop-filter: blur(3.9px);
      }
      .el-dialog {
        width: 510px;
        height: 470px;
        max-width: 94%;
        background-color: rgba(255, 255, 255, 0.5);
        .el-dialog__header {
          display: none;
        }
        .el-dialog__body {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          .block {
            position: relative;
            width: 150px;
            height: 150px;
            border-radius: 50%;
            overflow: hidden;
            img {
              position: absolute;
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .check-icon {
              object-fit: scale-down;
              width: auto;
              height: auto;
              max-width: 55%;
              max-height: 55%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          h4 {
            font-size: 26px;
            color: #fff;
            text-align: center;
            margin: 0.88em 0 2.69em;
          }
          button {
            width: 170px;
            height: 50px;
            background-color: #ff8ad7;
            outline: none;
            border: 0;
            border-radius: 4px;
            font-size: 18px;
            color: #fff;
            cursor: pointer;
            &:hover {
              background-color: #fda5df;
            }
          }
        }
      }
  }
}
</style>
