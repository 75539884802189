<template>
  <div class="msg-wrapper">
    <article>
      <div class="article-title">
        {{info.title}}
      </div>
      <div class="content" v-html="info.content">
        
      </div>
    </article>
  </div>
</template>

<script>

import {getNoticeDetail} from '@/api/api'
export default {
  name: "msgDetail",
  data() {
    return {
        id:0,
        info:{}
    };
  },
  created(){
    this.id = this.$route.params.id
    this.getDetail()
  },
  methods: {
    getDetail(){
      var param = {
        id:this.id
      }
      getNoticeDetail(param).then(res=>{
        this.info = res.data.data
      }).catch(err=>{
        console.log(err)
      })
    }
  },
};
</script>

<style scoped lang="less">
.msg-wrapper {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 5%;
  article {
    .article-title {
      font-size: 22px;
      color: #fff;
      text-align: center;
      font-weight: 700;
      margin-bottom: 2.5926%;
    }
    .content {
      font-size: 16px;
      color: #fff;
      line-height: 2;
    }
  }
}
</style>
