<template>
  <div class="user-wrapper">
    <div class="title">
      <router-link to="/editUserInfo" v-if="edit">修改个人信息</router-link>
      <h3>个人信息</h3>
      <h5>开启您的择友之旅~</h5>
    </div>
    <div class="user-info">
      <table>
        <tr>
          <td><span>姓名：</span>{{userInfo.nickname}}</td>
          <td><span>出生日期：</span>{{userInfo.birthday}}</td>
          <td><span>身高：</span>{{userInfo.height}}cm</td>
          <td><span>体重：</span>{{userInfo.weight}}kg</td>
        </tr>
        <tr>
          <td v-if="userInfo.gender == 1"><span>年收入：</span>{{userInfo.income}}</td>
          <td v-else><span>三围：</span>{{userInfo.measurements}}</td>
          <td><span>学历：</span>{{userInfo.education}}</td>
          <td><span>职业：</span>{{userInfo.occupation}}</td>
          <td><span>现居住地：</span>{{userInfo.address}}</td>
        </tr>
        <tr>
          <td colspan="4"><span>性格特点：</span>{{userInfo.personality}}</td>
        </tr>
        <tr>
          <td colspan="4"><span>兴趣爱好：</span>{{userInfo.interest}}</td>
        </tr>
        <tr>
          <td colspan="4"><span>恋爱愿望：</span>{{userInfo.wish.join('，')}}</td>
        </tr>
      </table>
    </div>
    <div class="user-pic content-list">
      <h5 class="list-title">
        个人照片
      </h5>
      <div class="wrapper">
        <div class="block" v-for="(item,index) in userInfo.personal_photos" :key="index">
          <a data-fancybox="gallery" :href="item">
            <img :src="item" alt="">
          </a>
        </div>
        
      </div>
    </div>
    <div class="user-video content-list">
      <h5 class="list-title">
        个人视频
      </h5>
      <div class="wrapper">
        <div class="block" v-for="(item,index) in userInfo.videos" :key="index">
          <a data-fancybox="video" :href="item" >
            <video :src="item" alt="" :poster="item+'?x-oss-process=video/snapshot,t_10000,m_fast'"></video>
            <div class="play-btn"><i class="el-icon-caret-right"></i></div>
          </a>
        </div>
        
      </div>
    </div>
    <div class="note-list content-list">
      <h5 class="list-title">
        个人日记 <router-link :to="'/note/'+user_id">查看更多</router-link>
      </h5>
      <div class="list">
        
        

        <div class="row" v-for="(item,index) in list" :key="index">
        <div class="date">日期：{{item.updatetime}}
          <el-popconfirm v-if="edit"
            title="确定删除这条日记吗？"
            @confirm="deletenote(item.id)"
          >
            <span slot="reference">删除</span>
          </el-popconfirm>
        </div>
        <div class="brief">
          <p>
           {{ item.content }}
          </p>
        </div>
        <div class="pic-list" v-if="item.images">
          <!-- <div class="block" v-for="(img,imgindex) in item.images" :key="imgindex">
            <a data-fancybox="gallery1" :href="img" :data-thumb="img">
            <video src="@/assets/video.mp4" alt="" poster="@/assets/note-pic.jpg"></video>
            <div class="play-btn"><i class="el-icon-caret-right"></i></div>
          </a> -->
          <!-- </div> -->
          <div class="block" v-for="(img,imgindex) in item.images" :key="imgindex">
            <a :data-fancybox="'gallery'+index" :href="img" :data-thumb="img">
              <img v-if="img.indexOf('.mp4')>0" :src="img+'?x-oss-process=video/snapshot,t_10000,m_fast'" alt="">
            <img v-else  :src="img" alt="">
            </a>
          </div>
          
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getDiaryList,deleteDiray,userinfo} from '@/api/api'
export default {
  name: "userInfo",
  data() {
    return {
      gender: 'm',
      list:[],
      page:1,
      last_page:1,
      userInfo:{},
      edit:false,
      self_userinfo:0
    };
  },
  props: {
    id: String,
  },
  watch:{
    '$route.params.id':{
      handler(newVal,oldVal){
        this.init()
      },
      immediate: true
    }
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll)
    console.log(2222)
    // this.init()
  },
  created(){
    
  },
  methods: {
    init(){
      this.user_id = this.$route.params.id
      this.self_userinfo = JSON.parse(localStorage.getItem('userinfo'))
      if(this.user_id==this.self_userinfo.id){
        this.edit = true
      }
      console.log(localStorage.getItem('userinfo'))
      this.getDiaryList()
      this.getUserInfo()
    },
    getUserInfo(){
      if(this.user_id>1){
        var param = {
          user_id:this.user_id
        }
      }else{
        var param = {
          
        }
      }
      
      userinfo(param).then(res=>{
        console.log(res.data)
        this.userInfo = res.data.data
      }).catch(err=>{
        console.log(err)
      })
    },
    deletenote(id){
      var param = {
        id
      }
      deleteDiray(param).then(res=>{
        if(res.data.code==1){
          this.$message.success(res.data.msg)
          this.list=[]
          this.getDiaryList()
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    handleScroll(e) {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const clientHeight = document.documentElement.clientHeight;
    
      // 检查是否滚动到了页面底部
      if (scrollHeight - scrollTop - clientHeight < 200) {
        // 200 是一个滚动到底部前的偏移量
          
          if(this.last_page>this.page){
            this.page++
            this.getDiaryList()
          }
          
        // 执行的代码......
      }
    },
    
    getDiaryList(){
      var that = this
      var param = {
        page:this.page,
        user_id:this.user_id 
      }
      getDiaryList(param).then(res => {
            if(res.data.code==1){
              that.list = that.list.concat(res.data.data.data)
              this.last_page = res.data.data.last_page
            }
            console.log(res.data.data.data)
          }).catch(err => console.log(err))
          console.log(this.list)
    },
    handleConfirm () {
      console.log('删除');
    },
  },
};
</script>

<style scoped lang="less">
.user-wrapper {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 5%;
  .title {
    margin-bottom: 3.704%;
    h3 {
      font-size: 28px;
      color: #fff;
      margin-bottom: 0.357143em;
    }
    h5 {
      font-size: 18px;
      color: #ebebeb;
      font-weight: normal;
    }
    a {
      float: right;
      color: #ff8ad7;
    }
  }
  .user-info {
    table {
      width: 100%;
      tr {
        td {
          color: #dcdcdc;
          font-size: 18px;
          padding: 0.5em 0.5em 0.5em 0;
          min-width: 20%;
          span {
            color: #fff;
            font-size: 20px;
          }
        }
      }
    }
  }
  .list-title {
    font-size: 20px;
    color: #fff;
    font-weight: normal;
    margin-bottom: 0.8em;
    overflow: hidden;
    a {
      float: right;
      color: #ff8ad7;
    }
  }
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    margin-left: 100px;
    .block {
      width: 150px;
      height: 114px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img, video {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
      }
      .play-btn {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        width: 23px;
        height: 23px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 50%;
        i {
          color: #fff;
        }
      }
    }
  }
  .content-list {
    margin: 10px 0 33px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .note-list {
    .list {
      margin-left: 100px;
    }
    .row {
      margin-bottom: 4.4444%;
      &:last-child {
        margin-bottom: 0;
      }
      .date {
        font-size: 20px;
        color: #fff;
        margin-bottom: 0.7em;
        span {
          font-size: 18px;
        }
        em {
          cursor: pointer;
          font-size: 18px;
          color: #ff8ad7;
          font-style: normal;
          margin-left: 2em;
        }
      }
      .brief {
        font-size: 16px;
        color: #f0f0f0;
        line-height: 1.8;
        margin-bottom: 1em;
      }
    }
  }
  .pic-list {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    .block {
      position: relative;
      width: 150px;
      height: 114px;
      img, video {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
