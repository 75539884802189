<template>
  <div class="search">
    <div class="search-input-wrapper">
      <form action="">
        <input type="text" @input="dosearch" v-model="keyword" placeholder="请输入想要输入的名称"><button><img src="@/assets/search-btn.png" alt=""></button>
      </form>
      <div class="gender-filter">
        <span :class="gender==1?'active':''" @click="filterMale"><img src="@/assets/male-icon-btn.png" alt=""></span>
        <span :class="gender==2?'active':''" @click="filterFemale"><img src="@/assets/female-icon-btn.png" alt=""></span>
      </div>
    </div>
    <div class="result-wrapper">
      <router-link v-for="(item,index) in list" :key="index" :to="'/userInfo/'+item.id" class="block">
        <div class="result-id">{{item.username}}</div>
        <div class="icon"><img src="@/assets/result-icon.png" alt=""></div>
        <div class="avatar">
          <div class="pic">
            <img :src="item.avatar" alt="">
          </div>
          <div class="ribbon">Lv{{ item.level }}</div>
        </div>
        <div class="brief">
          <p>姓名：{{ item.nickname }}</p>
          <p>年龄：{{ item.age }}</p>
          <p>性格特点：<span>{{ item.personality }}</span></p>
        </div>
      </router-link>
      <div class="block blank"></div>
      <div class="block blank"></div>
      
    </div>
    <el-pagination v-if="total>0"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage2"
      :page-sizes="[8, 24, 36, 48]"
      :page-size="8"
      layout=" prev, pager, next"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
import {searchUser} from '@/api/api'
export default {
  name: "searchPage",
  data() {
    return {
      gender: 0,
      keyword:'',
      userAvatarSrc: require("@/assets/user-avatar.png"),
      list:[],
      page:1,
      last_page:1,
      total:0
    };
  },
  mounted(){
    // window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    filterMale () {
      this.list = []
      this.page = 1
      this.gender = this.gender !== 1 ? 1 : 0
      this.dosearch()
    },
    filterFemale () {
      this.list = []
      this.page = 1
      this.gender = this.gender !== 2 ? 2 : 0
      this.dosearch()
    },
    handleCurrentChange(e){
      this.page = e
      this.dosearch()
    },
    dosearch(){
      var param = {
        gender:this.gender,
        page:this.page,
        keyword:this.keyword
      }
      searchUser(param).then(res=>{
        this.list = res.data.data.data
        // this.list = this.list.concat(res.data.data.data)
        this.last_page = res.data.data.last_page
        this.total = res.data.data.total
        console.log(this.list)
      }).catch(err=>{
        console.log(err)
      })
    },
    handleScroll(e) {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const clientHeight = document.documentElement.clientHeight;
      // console.log(scrollHeight)
      // console.log(scrollTop)
      // console.log(clientHeight)
    // console.log(e)
      // 检查是否滚动到了页面底部
      if (scrollHeight - scrollTop - clientHeight < 10) {
        // 200 是一个滚动到底部前的偏移量
          
          if(this.last_page>this.page){
            this.page++
            this.dosearch()
          }
          
        // 执行的代码......
      }
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-pager li{
  background: none !important;
  color: #fff;
}
/deep/ .el-pagination button{
  background: none !important;
}
/deep/ .el-pager li.active,/deep/ .el-pager li:hover{
  color: #f12f33;
}
/deep/ .el-pagination .btn-next,/deep/ .el-pagination .btn-prev ,/deep/ .el-pager li.btn-quicknext, /deep/ .el-pager li.btn-quickprev{
  color: #fff;
}
/deep/ .el-pagination{
  text-align: center;
}
.search {
  .search-input-wrapper {
    display: flex;
    margin-bottom: 8.16667%;
    form {
      flex: 1;
      position: relative;
      input {
        width: 100%;
        height: 70px;
        font-size: 18px;
        color: #fff;
        padding: 0 100px 0 80px;
        border-radius: 8px;
        background: url(@/assets/search-input-bg.png) no-repeat;
        background-position: right 15px center;
        background-color: rgba(255, 255, 255, 0.2);
        border: 0;
        outline: none;
        box-sizing: border-box;
        &::placeholder {
          color: #d7d7d7;
        }
      }
      button {
        position: absolute;
        top: 50%;
        left: 17px;
        transform: translateY(-50%);
        border: 0;
        outline: none;
        cursor: pointer;
        background: transparent;
        img {
          transition: all 0.5s;
        }
        &:hover {
          img {
            filter: contrast(0) brightness(200%);
          }
        }
      }
    }
    .gender-filter {
      span {
        display: inline-block;
        width: 80px;
        height: 70px;
        line-height: 70px;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
        transition: all 0.5s;
        margin-left: 25px;
        img {
          transition: all 0.5s;
        }
      }
      .active {
        background-color: rgba(138, 254, 255, 0.4);
        img {
          filter: contrast(0) brightness(200%);
        }
      }
    }
  }
  .result-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .block {
      position: relative;
      background: url(@/assets/result-block-bg.png) no-repeat;
      background-size: 100% 100%;
      width: 286px;
      max-width: 100%;
      display: flex;
      align-items: center;
      padding: 30px 5px 8px 17px;
      box-sizing: border-box;
      margin-bottom: 3.416667%;
      .result-id {
        position: absolute;
        top: 0;
        left: 0;
        background: url(@/assets/result-id-bg.png) no-repeat;
        background-size: 100% 100%;
        padding: 0 40px 0 14px;
        font-size: 18px;
        color: #fff;
        font-weight: 700;
        line-height: 35px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 8em;
      }
      .icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 30.769231%;
        transform: translate(8px, -5px);
      }
      .avatar {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 11px;
        .pic {
          position: relative;
          z-index: 1;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          border: 1px solid #f2f2f2;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 68px;
            height: 68px;
            border-radius: 50%;
            object-fit: cover
          }
        }
        .ribbon {
          position: relative;
          z-index: 2;
          width: 99px;
          height: 31px;
          line-height: 28px;
          background: url(@/assets/ribbon.png) no-repeat;
          background-size: contain;
          font-size: 16px;
          color: #fff;
          text-align: center;
          margin-top: -20px;
        }
      }
      .brief {
        position: relative;
        z-index: 1;
        font-size: 16px;
        color: #fff;
        line-height: 1.8;
        span {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
