<template>
  <div class="home-wrapper">


    <div class="block" v-for="(item,index) in list" :key="index">
      <div class="title">
        <router-link to="/userInfo/1" class="avatar-wrapper mini-avatar-wrapper">
          <div class="avatar mini-avatar">
            <div class="pic">
              <img :src="item.user.avatar" alt="">
            </div>
            <div class="ribbon">Lv1</div>
          </div>
          <div class="info">
            <h5>{{item.user.username}}</h5>
          </div>
        </router-link>
        <span>{{item.createtime}}</span>
      </div>
      <div class="content" @click="openArticle(item)">
        <div class="brief" style="--line: 2;">
          <p>{{ item.content }}</p>
        </div>
        <ul class="list" v-if="item.images">
          <!-- <li>
            <div class="wrapper">
              <video src="" poster="@/assets/home-article-pic.jpg"></video>
              <div class="play-btn"><i class="el-icon-caret-right"></i></div>
            </div>
          </li> -->
          <li v-for="(items,indexs) in item.images" >
            <div class="wrapper">
              <img v-if="items.indexOf('.mp4')>0"  :src="items+'?x-oss-process=video/snapshot,t_10000,m_fast'" alt="">
              <img v-else  :src="items" alt="">
            </div>
          </li>
         
          <li class="blank"></li>
        </ul>
      </div>
    </div>

    <div class="block blank"></div>
    <el-dialog
      :visible.sync="dialogVisible">
      <div class="brief" style="--line: 2;">
        <p>{{ showcontent.content }}</p>
      </div>
      <ul class="list" v-if="showcontent.images">
        <!-- <li >
          <a class="wrapper" data-fancybox="gallery" :href="require('@/assets/video.mp4')" :data-thumb="require('@/assets/home-article-pic.jpg')">
            <video src="@/assets/video.mp4" poster="@/assets/home-article-pic.jpg"></video>
            <div class="play-btn"><i class="el-icon-caret-right"></i></div>
          </a>
        </li> -->
        <li v-for="(items,indexs) in showcontent.images">
          <a class="wrapper" data-fancybox="gallery" :href="items" :data-thumb="items">
            <img v-if="items.indexOf('.mp4')>0" :src="items+'?x-oss-process=video/snapshot,t_10000,m_fast'" alt="">
            <img v-else  :src="items" alt="">
          </a>
        </li>
        <!-- <li>
          <a class="wrapper" data-fancybox="gallery" :href="require('@/assets/home-article-pic.jpg')" :data-thumb="require('@/assets/home-article-pic.jpg')">
            <img src="@/assets/home-article-pic.jpg" alt="">
          </a>
        </li> -->
      </ul>
    </el-dialog>
  </div>
</template>

<script>
import {getDiaryList} from '@/api/api'
import HeaderComponent from "@/components/HeaderComponent.vue";
export default {
  name: "homePage",
  components: {
    HeaderComponent,
  },
  data() {
    return {
      userAvatarSrc: require("@/assets/user-avatar.png"),
      dialogVisible: false,
      list:[],
      showcontent:{}
    };
  },
  created(){
    this.getDiaryList()
  },
  methods: {
    getDiaryList(){
      var that = this
      getDiaryList(this.loginForm).then(res => {
            if(res.data.code==1){
              that.list = res.data.data.data
            }
            console.log(res.data.data.data)
          }).catch(err => console.log(err))
          console.log(this.list)
    },
    openArticle (item) {
      this.showcontent = item
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less">
.home-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .block {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    width: 32%;
    margin-bottom: 2%;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 2.08333% 10.41667% 2.08333% 8.854167%;
      background-image: url(@/assets/pin-icon.png);
      background-repeat: no-repeat;
      background-position: 4px 2px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      span {
        font-size: 16px;
        color: #ff8ad7;
      }
    }
    .mini-avatar-wrapper {
      .mini-avatar {
        .pic {
          width: 50px;
          height: 50px;
          img {
            width: 42px;
            height: 42px;
          }
          &::after {
            display: none;
          }
        }
        .ribbon {
          width: 61px;
          height: 20px;
          font-size: 11px;
          margin-top: -15px;
          line-height: 18px;
        }
      }
    }
    .content {
      padding: 4.16667% 10.41667%;
      .brief {
        cursor: pointer;
        --line: 5;
        font-size: 16px;
        color: #fff;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: var(--line);
        height: calc(1.6em * var(--line));
        line-height: 1.6;
        margin-bottom: 0.7em;
      }
      .list {
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: 27.96053%;
          .wrapper {
            position: relative;
            width: 100%;
            height: 0;
            padding: 50% 0;
            display: flex;
            justify-content: center;
            align-items: center;
            img, video {
              position: absolute;
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .play-btn {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 1;
              width: 23px;
              height: 23px;
              background-color: rgba(0, 0, 0, 0.6);
              border-radius: 50%;
              i {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
