<template>
  <div class="msg-wrapper">
    <router-link :to="'/msgDetail/'+item.id" class="row" v-for="(item,index) in list" :key="index">
      <div class="content">
        <div class="icon">
          <img src="@/assets/msg-icon1.png" alt="">
        </div>
        <div class="title">
          <h4>{{item.title}}</h4>
          <h5>{{item.content}}</h5>
        </div>
      </div>
      <div class="info">
        <h5>{{item.createtime}}</h5>
        <em v-if="item.is_read==0">1</em>
      </div>
    </router-link>
    <el-pagination v-if="total>0"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage2"
      :page-sizes="[8, 24, 36, 48]"
      :page-size="8"
      layout=" prev, pager, next"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
import {getNoticeList} from '@/api/api'
export default {
  name: "msgPage",
  data() {
    return {
        list:[],
        page:1,
        last_page:1,
        total:0
    };
  },
  created(){
    this.getlist()
  },
  methods: {
    getlist(){
      var param = {
        page:this.page
      }
      getNoticeList(param).then(res=>{
        this.list = res.data.data.data
        this.last_page = res.data.data.last_page
        this.total = res.data.data.total
        console.log(this.list)
      }).catch(err=>{
        console.log(err)
      })
    }
  },
};
</script>

<style scoped lang="less">
/deep/ .el-pager li{
  background: none !important;
  color: #fff;
}
/deep/ .el-pagination button{
  background: none !important;
}
/deep/ .el-pager li.active,/deep/ .el-pager li:hover{
  color: #f12f33;
}
/deep/ .el-pagination .btn-next,/deep/ .el-pagination .btn-prev ,/deep/ .el-pager li.btn-quicknext, /deep/ .el-pager li.btn-quickprev{
  color: #fff;
}
/deep/ .el-pagination{
  text-align: center;
}
.msg-wrapper {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 5%;
  .row {
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.01852% 2.77778% 1.01852% 1.852%;
    transition: all 0.5s;
    margin-bottom: 2.77778%;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background-color: rgba(255, 138, 215, 0.4);
    }
    .content {
      display: flex;
      align-items: center;
      flex: 1;
      margin-right: 20px;
    }
    .icon {
      position: relative;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 13px;
      img {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover
      }
    }
    .title {
      flex: 1;
    }
    h4 {
      font-size: 22px;
      color: #fff;
      margin-bottom: 3px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }
    h5 {
      font-size: 18px;
      color: #fff;
      font-weight: normal;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }
    .info {
      white-space: nowrap;
      text-align: right;
      em {
        display: inline-block;
        line-height: 24px;
        font-size: 18px;
        color: #fff;
        background-color: #e11e1e;
        border-radius: 12px;
        font-style: normal;
        min-width: 24px;
        padding: 0 3px;
        text-align: center;
        box-sizing: border-box;
        margin-top: 12px;
      }
      .hidden {
        visibility: hidden;
      }
    }
  }
}
</style>
