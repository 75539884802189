<template>
  <div class="login">
    <div class="login-panel">
      <h3>登录二次元相亲网站</h3>
      <h5>来二次元婚恋网开启新的旅程~~~</h5>
      <div class="login-form">
        <el-form
          :model="loginForm"
          :rules="rules"
          ref="loginForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label-width="0" prop="account">
            <el-input v-model="loginForm.account" placeholder="请输入账号">
            </el-input>
          </el-form-item>
          <el-form-item label-width="0" prop="password">
            <el-input
              v-model="loginForm.password"
              placeholder="请输入密码"
              show-password
            >
            </el-input>
          </el-form-item>
          <el-form-item label-width="0">
            <el-checkbox-group v-model="agree">
              <el-checkbox>
                我已阅读并同意<a href="">《隐私协议》</a>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-button
            type="primary"
            @click="submitForm('loginForm')"
            :disabled="!agree"
          >
            登录
          </el-button>
          <div class="register-wrapper">
            <p>没有账号，<router-link to="/register">立即注册</router-link></p>
            <router-link to="/forgetPassword">忘记密码</router-link>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
 import {login} from '@/api/api'
 import { 
  Message} from 'element-ui';
export default {
  name: "loginPage",
  data() {
    return {
      agree: false,
      loginForm: {
        account: "",
        password: "",
      },
      rules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  
  methods: {
    submitForm(formName) {
      
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.loginForm)
          
          login(this.loginForm).then(res => {
            if(res.data.code==1){
              localStorage.setItem('ecy_token',res.data.data.userinfo.token)
              localStorage.setItem('userinfo',JSON.stringify(res.data.data.userinfo))
              this.$router.push({
                name: "loginSuccess",
              });
            }else{
              Message.error(res.data.msg)
            }
            console.log(res)
          }).catch(err => console.log(err))
          
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  watch:{
    $route(to, from) {
       window.location.reload(); //监测到路由发生跳转时刷新一次页面
   },
  }
};
</script>

<style scoped lang="less">
.login {
  background: url(@/assets/background/login-bg.jpg);
  background-size: cover;
  min-height: 100vh;
  overflow: hidden;
}
.login-panel {
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 0 38px rgba(4, 6, 11, 0.16);
  width: 687px;
  max-width: 100%;
  min-height: 100vh;
  padding: 1% 5.3646%;
  box-sizing: border-box;
  h3 {
    font-size: 34px;
    color: #0a0405;
    margin: 0 0 0.588em;
  }
  h5 {
    font-size: 22px;
    color: #797979;
    font-weight: normal;
    margin: 0;
  }
}
.login-form {
  margin-top: 90px;
  ::v-deep .el-input {
    .el-input__inner {
      font-size: 18px;
      height: 60px;
      line-height: 60px;
      &::placeholder {
        color: #b6b5b5;
      }
    }
    .el-input__inner:focus {
      border-color: #ff8ad7;
    }
  }
  ::v-deep .el-checkbox {
    .el-checkbox__inner {
      width: 15px;
      height: 15px;
      border: 1px solid #ff8ad7;
    }
    .el-checkbox__label {
      font-size: 18px;
      color: #3a3a3a;
      a {
        color: #ff8ad7;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #ff8ad7;
    }
  }
  ::v-deep .el-button {
    background-color: #ff8ad7;
    color: #fff;
    font-size: 18px;
    border: 0;
    width: 100%;
    height: 60px;
  }
  ::v-deep .el-button--primary.is-disabled {
    opacity: 0.5;
    background-color: #ff8ad7;
  }
}
.register-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  font-size: 18px;
  margin-top: 15px;
  a {
    color: #ff8ad7;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    margin: 0;
  }
}
</style>
