import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import HomePage from "../views/Home/HomePage.vue";
import MsgPage from "../views/Home/MsgPage.vue";
import MsgDetail from "../views/Home/MsgDetail.vue";
import ChangeAvatar from "../views/UserCenter/ChangeAvatar.vue";
import WriteNote from "../views/Home/WriteNote.vue";
import NotePage from "../views/Home/NotePage.vue";
import UserInfo from "../views/UserCenter/UserInfo.vue";
import EditUserInfo from "../views/UserCenter/EditUserInfo.vue";
import Search from "../views/Home/Search.vue";
import Login from "../views/Login/Login.vue";
import Register from "../views/Register/Register.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    children: [
      {
        path: "/",
        meta: {index: "1", isHome: true, title: "主页大厅"},
        component: HomePage,
      },
      {
        path: "/writeNote",
        meta: {index: "2", title: "写日记"},
        component: WriteNote,
      },
      {
        path: "/note/:id",
        meta: {title: "日记"},
        component: NotePage,
      },
      {
        path: "/search",
        meta: {index: "3", title: "搜索"},
        component: Search,
      },
      {
        path: "/msg",
        meta: {index: "4", title: "消息通知"},
        component: MsgPage,
      },
      {
        path: "/msgDetail/:id",
        meta: {index: "4", title: "消息通知"},
        component: MsgDetail,
      },
      {
        path: "/changeAvatar",
        meta: {title: "头像替换"},
        component: ChangeAvatar,
      },
      {
        path: "/userInfo/:id",
        meta: {title: "个人中心", isEditPage: false},
        component: UserInfo,
        props: true,
      },
      {
        path: "/editUserInfo",
        meta: {title: "个人中心", isEditPage: true},
        component: EditUserInfo,
      },
      {
        path: "/login",
        name: "login",
        component: Login,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/loginSuccess",
    name: "loginSuccess",
    // route level code-splitting
    // this generates a separate chunk (loginSuccess.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "loginSuccess" */ "../views/Login/LoginSuccess.vue"
      ),
  },
  {
    path: "/logoutSuccess",
    name: "logoutSuccess",
    // route level code-splitting
    // this generates a separate chunk (loginSuccess.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "loginSuccess" */ "../views/Login/logoutSuccess.vue"
      ),
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    // route level code-splitting
    // this generates a separate chunk (forgetPassword.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "forgetPassword" */ "../views/Login/ForgetPassword.vue"
      ),
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/registerSuccess",
    name: "registerSuccess",
    // route level code-splitting
    // this generates a separate chunk (registerSuccess.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "registerSuccess" */ "../views/Register/RegisterSuccess.vue"
      ),
  },
  {
    path: "/registerAsFemale",
    name: "registerAsFemale",
    // route level code-splitting
    // this generates a separate chunk (registerAsFemale.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "registerAsFemale" */ "../views/Register/RegisterAsFemale.vue"
      ),
  },
  {
    path: "/registerAsMale/:gender",
    name: "registerAsMale",
    // route level code-splitting
    // this generates a separate chunk (registerAsMale.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "registerAsMale" */ "../views/Register/RegisterAsMale.vue"
      ),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
