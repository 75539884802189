<template>
  <div :class="$route.meta.isHome?'home home-page':'home'">
    <HeaderComponent />
    <main>
      <div class="container" ref="container">
        <router-view></router-view>
        <slot></slot>
      </div>
    </main>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import { Fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css';

export default {
  name: "homePage",
  components: {
    HeaderComponent,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  props: {
    options: Object,
  },
  mounted() {
    Fancybox.bind(this.$refs.container, '[data-fancybox]', {
      ...(this.options || {}),
    });
  },
  updated() {
    Fancybox.unbind(this.$refs.container);
    Fancybox.close();

    Fancybox.bind(this.$refs.container, '[data-fancybox]', {
      ...(this.options || {}),
    });
  },
  unmounted() {
    Fancybox.destroy();
  },
};
</script>

<style lang="less">
.fancybox__container {
  z-index: 10000 !important;
}
.home {
  position: relative;
  padding: 3% 0;
  min-height: 100vh;
  background: url(@/assets/background/home-mall-bg1.jpg) no-repeat;
  background-size: cover;
  background-attachment: fixed;
  .home-title {
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    background-color: transparent;
    margin-bottom: 0.625em;
  }
}
.home-page {
  background-image: url(@/assets/background/home-mall-bg.jpg);
}
.container {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}
.avatar-wrapper {
  display: flex;
  align-items: center;
  .avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    .pic {
      position: relative;
      z-index: 1;
      width: 112px;
      height: 112px;
      border-radius: 50%;
      border: 1px solid #f2f2f2;
      display: flex;
      justify-content: center;
      align-items: center;
      &::after {
        position: absolute;
        display: block;
        content: "";
        background: url(@/assets/avatar-border.png) no-repeat;
        background-size: contain;
        width: 130px;
        height: 131px;
      }
      img {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        object-fit: cover
      }
    }
    .ribbon {
      position: relative;
      z-index: 2;
      width: 125px;
      height: 40px;
      line-height: 36px;
      background: url(@/assets/ribbon.png) no-repeat;
      background-size: contain;
      font-size: 20px;
      color: #fff;
      text-align: center;
      margin-top: -20px;
    }
  }
  .editAvatar {
    .pic {
      &::before {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        content: "更改头像";
        border-radius: 50%;
        color: #fff;
        font-size: 18px;
        transition: all 0.5s;
        opacity: 0;
      }
      &:hover {
        &::before {
          opacity: 1;
        }
      }
    }
  }
  .info {
    margin-left: 12px;
    h4 {
      font-size: 24px;
      color: #fff;
      margin: 0 0 0.583em;
    }
    h5 {
      font-size: 20px;
      color: #fff;
      font-weight: normal;
      margin: 0;
    }
  }
}
main {
  .el-dialog__wrapper {
    backdrop-filter: blur(3.9px);
  }
  .el-dialog {
    margin-top: 50vh !important;
    transform: translate(0, -50%);
    width: 1026px;
    max-width: 94%;
    background-color: rgba(255, 255, 255, 0.3);
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      height: 100%;
      box-sizing: border-box;
      padding: 3% 4%;
      .brief {
        font-size: 16px;
        color: #fff;
        line-height: 1.6;
        margin-bottom: 1em;
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        gap: 11px;
        li {
          width: 85px;
          .wrapper {
            position: relative;
            width: 100%;
            height: 0;
            padding: 50% 0;
            display: flex;
            justify-content: center;
            align-items: center;
            img, video {
              position: absolute;
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .play-btn {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 1;
              width: 23px;
              height: 23px;
              background-color: rgba(0, 0, 0, 0.6);
              border-radius: 50%;
              i {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
