<template>
  <div class="login-bg">
    <div class="gender-choose">
      <h2>性别选择</h2>
      <div class="wrapper">
        <router-link to="/registerAsMale/1">
          <h3>男</h3>
          <img src="@/assets/male-icon.png" alt="" />
        </router-link>
        <img class="icon" src="@/assets/aniya-pic.png" />
        <router-link to="/registerAsMale/0">
          <h3>女</h3>
          <img src="@/assets/female-icon.png" alt="" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "registerPage",
};
</script>

<style scoped lang="less">
.login-bg {
  width: 100vw;
  min-height: 100vh;
  background: url(@/assets/background/login-window-bg.jpg) no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .gender-choose {
    width: 1200px;
    max-width: 94%;
    h2 {
      font-size: 40px;
      color: #fff;
      text-align: center;
      margin: 1em 0 1.875em;
    }
    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      a {
        display: block;
        width: 43.333%;
        text-align: center;
        color: #fff;
        text-decoration: none;
        transition: all 0.5s;
      }
      h3 {
        font-size: 30px;
        margin: 0;
      }
      img {
        max-width: 100%;
        height: auto;
      }
      .icon {
        margin-bottom: 40px;
      }
      &:hover {
        a {
          opacity: 0.5;
          filter: brightness(70%);
          &:hover {
            opacity: 1;
            filter: brightness(100%);
          }
        }
      }
    }
  }
}
</style>
